import React from "react";

const AboutUs = () => {
  return (
    <section id="about-us" className="bg-dark py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center pt-10 pb-10">
          <h2 className="text-4xl font-bold text-white mb-4">
            Bienvenidos a Somos Nodos
          </h2>
          <p className="text-lg text-gray-300 mb-6">
            En <strong>Somos Nodos</strong>, conectamos ideas, experiencias y
            aprendizajes de expertos en diversas disciplinas. A través de
            conversaciones auténticas y profundas, buscamos ofrecer contenido
            que inspire y transforme, dejando una huella positiva en cada
            oyente.
          </p>
          <p className="text-lg text-gray-300 mb-6">
            Nuestro podcast no es solo para escuchar, sino para reflexionar y
            actuar. Nos enfocamos en temas que te desafían a ver el mundo desde
            una nueva perspectiva, mientras sigues construyendo una mentalidad
            más fuerte y enfocada en el crecimiento.
          </p>
          <p className="text-lg text-gray-300 mb-6">
            Únete a esta comunidad de personas que buscan impactar sus vidas de
            manera positiva. Cada episodio es una oportunidad para profundizar
            en nuevas ideas, explorar diferentes enfoques, y aplicar
            aprendizajes que verdaderamente importan.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
