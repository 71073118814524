import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const quotes = [
  {
    quote:
      "La educación es lo que queda después de que uno ha olvidado lo que aprendió en la escuela.",
    author: "Albert Einstein",
  },
  {
    quote: "La felicidad de tu vida depende de la calidad de tus pensamientos.",
    author: "Marco Aurelio",
  },
  {
    quote:
      "La única forma de mejorar es aceptando que lo que eres hoy no es suficiente para lo que puedes llegar a ser.",
    author: "David Goggins",
  },
];

const QuoteComponent = () => {
  const [currentQuote, setCurrentQuote] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote((prevQuote) => (prevQuote + 1) % quotes.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="max-w-4xl mx-auto my-16 p-10 rounded-lg bg-gray-900 text-gray-100 text-center shadow-lg overflow-hidden">
      <div className="h-[150px] flex items-center justify-center">
        <AnimatePresence>
          <motion.div
            key={currentQuote}
            initial={{ opacity: 0, x: 100 }} // Entra desde la derecha
            animate={{ opacity: 1, x: 0 }} // Se mueve hacia el centro
            exit={{ opacity: 0, x: -100 }} // Sale hacia la izquierda
            transition={{ duration: 0.8 }}
            className="w-full"
          >
            <blockquote className="text-2xl font-semibold italic">
              "{quotes[currentQuote].quote}"
            </blockquote>
            <cite className="block mt-4 text-lg text-gray-400">
              - {quotes[currentQuote].author}
            </cite>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default QuoteComponent;
