import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faComputer,
  faBrain,
  faBalanceScale,
} from "@fortawesome/free-solid-svg-icons";

const episodes = [
  {
    title:
      "Episodio 3: Adaptabilidad al cambiar de Carrera | Lic.Felipe Machado",
    description:
      "Felipe Machado, de kinesiólogo a desarrollador, comparte su historia de transición hacia IT y cómo enfrentar con éxito los desafíos de un mercado tecnológico en constante cambio.",
    link: "https://www.youtube.com/watch?v=HITIpav32ak",
    icon: faCode,
  },
  {
    title:
      "Episodio 4: Ciberseguridad: Primeros Pasos | Ing.Ileana Barrionuevo",
    description:
      "Ileana Barrionuevo, Ingeniera en Sistemas, nos guía en los primeros pasos hacia una carrera en ciberseguridad, explorando roles clave y oportunidades en este campo.",
    link: "https://www.youtube.com/watch?v=GSn2oU3lHhk",
    icon: faComputer,
  },
  {
    title: "Episodio 5: Derecho Digital | Abog. Martina Fumiere",
    description:
      "Martina Fumiere, abogada especializada en derecho digital y nuevas tecnologías, nos guía en el derecho en la era tecnológica, abordando temas como jurisprudencia, copyright y el uso de inteligencia artificial en la justicia.",
    link: "https://youtu.be/FiMw5zbAKQ8",
    icon: faBalanceScale,
  },
];

const Cards = () => {
  return (
    <section data-testid="episodios-seccion" className="py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 gap-6">
        {episodes.map((episode, index) => (
          <div
            key={index}
            className="bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-2xl transform hover:-translate-y-2 transition-transform duration-300 ease-in-out"
          >
            {/* Icono centrado */}
            <div className="mb-5 pt-10 pb-10 text-center">
              <FontAwesomeIcon
                icon={episode.icon}
                size="6x"
                className="text-white"
              />
            </div>
            <h3 className="text-xl font-bold mb-4 text-white">
              {episode.title}
            </h3>
            <p className="text-gray-400 mb-6">{episode.description}</p>
            <a
              href={episode.link}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-black text-white py-2 px-4 rounded-lg hover:bg-white hover:text-black transition duration-300 ease-in-out"
            >
              Escuchar
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Cards;
